/**
 * Listeners
 */

function init() {

    require('../../../../node_modules/mdn-polyfills/String.prototype.includes');

    require('../../../../node_modules/mdn-polyfills/Array.prototype.includes');
    require('../../../../node_modules/mdn-polyfills/Array.prototype.forEach');

    /*if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = function (callback, thisArg) {
            thisArg = thisArg || window;
            for (var i = 0; i < this.length; i++) {
                callback.call(thisArg, this[i], i, this);
            }
        };
    }

    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }*/

}




window.addEventListener('load', () => {
    init();
});

export default init;