function setup() {
    let socialSharing = document.querySelectorAll('.js-social-sharing-video');


    if( !!socialSharing ){

        socialSharing.forEach(function (socialSharingItem) {
            let plusButton = socialSharingItem.querySelector('.main-container');
            let iconShare = socialSharingItem.querySelectorAll('.js-icon-share');


            if(!!plusButton) {
                plusButton.addEventListener('click', function () {
                    if(socialSharingItem.classList.contains('active')) {
                        socialSharingItem.classList.remove('active');
                    } else {
                        socialSharingItem.classList.add('active');
                    }
                });
            }

            if(!!iconShare) {
                iconShare.forEach(function (item) {
                    item.addEventListener('click',function () {

                        if( item.classList.contains('js-facebook-share') ||
                            item.classList.contains('js-twitter-share') ||
                            item.classList.contains('js-linkedin-share')
                        ) {
                            let hrefItem = item.dataset.url;
                            window.open(hrefItem,'share-dialog','width=626,height=436');
                        }

                        if(socialSharingItem.classList.contains('active')) {
                            socialSharingItem.classList.remove('active');
                        }
                    });
                })
            }


        });
    }
}

export default setup;
