function setup() {
    let searchIcon = document.querySelectorAll('.js-search-mobile');
    let closePopup = document.querySelector('.js-close-search');
    let searchPopup = document.querySelector('.js-search-popup');
    let searchSuggestions = document.querySelectorAll('.js-search-suggestion');

    if( !!searchIcon ){
        searchIcon.forEach(function (item) {
            item.addEventListener('click',function (e) {
                searchPopup.classList.remove('hidden');
                searchPopup.classList.add('visible');
            });
        });
    }

    if( !!closePopup ){
        closePopup.addEventListener('click',function (e) {
            searchPopup.classList.remove('visible');
            searchPopup.classList.add('hidden');
        });
    }

    if(!!searchSuggestions) {
        searchSuggestions.forEach(function (item) {
            item.addEventListener('click',function (e) {
                let dataSearch = this.dataset.search;
                let searchInput = document.getElementById('search-popup-form-input');
                let searchForm = document.getElementById('search-popup-form');
                searchInput.classList.add('invisible-value');
                searchInput.value = dataSearch;
                searchForm.submit();
            });
        });
    }
}

export default setup;
