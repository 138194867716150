function setup() {
    let subMenuItems = document.querySelectorAll('.js-submenu__item');

    if( !!subMenuItems ){
        let subsubMenuContainer = document.querySelector('.js-sub-sub-menu-container');
        let previousId = null;
        subMenuItems.forEach(function (subMenuItem) {
            subMenuItem.addEventListener('mouseover',function (e) {
                if( previousId != subMenuItem.dataset.menuItem ){
                    let content = subMenuItem.querySelector('.sub-sub-menu__container');
                    let innerHtml = '';
                    if( !! content ){
                        innerHtml = content.innerHTML;
                    }
                    subsubMenuContainer.innerHTML = innerHtml;
                    let innerElements = subsubMenuContainer.querySelectorAll('.sub-sub-menu__item');

                    if( !!innerElements ){
                        innerElements.forEach(function (innerElement, index) {
                            setTimeout(function () {
                                innerElement.classList.add('visible');
                            }, index * 100);
                        });
                    }
                    previousId = subMenuItem.dataset.menuItem;
                }





            });
        });
    }
}

export default setup;
