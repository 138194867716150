import getSiblings from "./getSiblings";

function setup() {
    let accordionItem = document.querySelectorAll('.js-accordion-item');


    if( !!accordionItem ){
        accordionItem.forEach(function (item) {

            let acc = item.querySelector('.accordion');

            if( !!acc ){
                acc.addEventListener('click', function() {

                    getSiblings.getSiblings(this.parentElement).forEach((sibling) =>{
                        let accSibling = sibling.querySelector('.accordion');
                        if( !!accSibling && accSibling.classList.contains("active") ) {
                            accSibling.click();
                        }
                    });


                    this.classList.toggle("active");


                    let panel = this.nextElementSibling;

                    if (panel.style.maxHeight) {
                        panel.style.maxHeight = null;
                    } else {
                        panel.style.maxHeight = panel.scrollHeight + "px";
                    }
                });
            }
        });
    }
}

export default setup;
