function setup() {
    let menuMobileContainer = document.querySelector('.js-header-mobile');

    if( !!menuMobileContainer ){
        let openMenu = document.querySelectorAll('.js-open-menu');
        let closeMenu = document.querySelector('.js-close-menu');

        openMenu.forEach(function (btn) {
            btn.addEventListener('click', function () {
                menuMobileContainer.classList.add('open');
            });
        });

        closeMenu.addEventListener('click', function () {
            menuMobileContainer.classList.remove('open');
        });


        window.onscroll = function() {
            let scrollTop =  document.body.scrollTop || document.documentElement.scrollTop;
            if (scrollTop >= 50 || scrollTop >= 50) {
                menuMobileContainer.classList.add('fixed');
            }else if(scrollTop <= 50 || scrollTop <= 50){
                menuMobileContainer.classList.remove('fixed');
            }
        }

    }
}

export default setup;
