function setup() {
    resizeImage();
}

function resizeImage(){
    let elements = document.querySelectorAll('.js-content-module-with-image');
    let w = window.innerWidth;

    let rtime;
    let timeout = false;
    let delta = 200;



    if( !!elements ){

        resize();

        window.addEventListener('resize', function(event) {
            event.preventDefault();
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(resizeend, delta);
            }
        }, false)

    }



    function resizeend() {
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;
            resize();
        }
    }



    function resize() {

        w = window.innerWidth;

        elements.forEach(function(element){

            let imagePercentDesktop = 110; //percentage of the content wrapper height

            let imagePercentTablet = 110; //percentage of the content wrapper height

            let contentWrapper = element.querySelector('.content-module-with-image-content-wrapper');
            let imageWrapper = element.querySelector('.content-module-with-image-image');

            if( (w >= 768) && (w < 992) ) {
                //Note: images have a maximum height of 380px
                imageWrapper.style.height = ((contentWrapper.offsetHeight)*imagePercentTablet/100)+"px";
                element.style.height = ((contentWrapper.offsetHeight)*imagePercentTablet*1.1/100)+"px";
            } else if( w >= 992) {
                //Note: images have a maximum height of 380px
                imageWrapper.style.height = ((contentWrapper.offsetHeight)*imagePercentDesktop/100)+"px";
                element.style.height = ((contentWrapper.offsetHeight)*imagePercentDesktop*1.3/100)+"px";
            } else {
                if(imageWrapper.classList.contains('colorful')) {
                    imageWrapper.style.height = "300px";
                } else {
                    imageWrapper.style.height = "365px";
                }
                element.style.height = "auto";
            }
        });
    }

}



export default setup;
