import videoClickEvent from "./videoClickEvent";

/**
 * Listeners
 */
function init() {
        let videoElements = document.querySelectorAll('[data-popup-video]') ? document.querySelectorAll('[data-popup-video]') : null;
        videoClickEvent.videoClickEvent(videoElements);
}


window.addEventListener('load', () => {
    init();
});

export default init;
