import polyfills from './exceptions/polyfills';

import videoClick from './modules/videoClick';

import headerDesktop from './modules/header-desktop';
import headerMobile from './modules/header-mobile';
import latestVideos from './modules/latest-videos';
import helpfullArticle from './modules/helpfull-article';
import sameHeight from './modules/same-height';
import socialSharing from './modules/social-sharing';
import socialSharingVideoCard from './modules/social-sharing-video-card';
import starsPageReview from './modules/stars-page-review';
import pubSlider from './modules/pub-slider';
import searchHeaderDesktop from './modules/search-header-desktop';
import searchHeaderMobile from './modules/search-header-mobile';
import contentModuleResizeImage from './modules/content-module-resize-image';
import innerMenuNav from './modules/inner-menu-nav';
import accordion from './modules/accordion';
import cookiesPolicy from './modules/cookiesPolicy';


import moduleLoader, {loadModule} from './utils/moduleLoader'

moduleLoader({
	headerDesktop,
	latestVideos,
	helpfullArticle,
	sameHeight,
	socialSharing,
	socialSharingVideoCard,
	starsPageReview,
	headerMobile,
	pubSlider,
	searchHeaderDesktop,
	searchHeaderMobile,
	contentModuleResizeImage,
	innerMenuNav,
	accordion,
	cookiesPolicy
})();

loadModule('headerDesktop');
loadModule('latestVideos');
loadModule('helpfullArticle');
loadModule('sameHeight');
loadModule('socialSharing');
loadModule('socialSharingVideoCard');
loadModule('starsPageReview');
loadModule('headerMobile');
loadModule('pubSlider');
loadModule('searchHeaderDesktop');
loadModule('searchHeaderMobile');
loadModule('contentModuleResizeImage');
loadModule('innerMenuNav');
loadModule('accordion');
loadModule('cookiesPolicy');

window.addEventListener('load', () => {


});
