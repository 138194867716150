import getSiblings from "./getSiblings";

function setup() {
   let innerMenus = document.querySelectorAll('.js-inner-menu');

    let rtime;
    let timeout = false;
    let delta = 200;

    let isDown = false;
    let startX;
    let scrollLeft;


    if( !!innerMenus ){
        innerMenus.forEach(function (menu) {

             let navWrapper = menu.nextElementSibling;
             let elementsContainer = menu.querySelector('.elements-container');
             let elementsContainerWidth = elementsContainer.offsetWidth;

             navAction(elementsContainer, elementsContainerWidth, navWrapper, isDown, startX, scrollLeft);

        });



        window.addEventListener('resize', function(event) {
            event.preventDefault();
            rtime = new Date();
            if (timeout === false) {
                timeout = true;
                setTimeout(resizeend, delta);
            }
        }, false)


    }

    function resizeend() {
        if (new Date() - rtime < delta) {
            setTimeout(resizeend, delta);
        } else {
            timeout = false;
            resize();
        }
    }

    function resize() {

        innerMenus.forEach(function (menu) {
            let navWrapper = menu.nextElementSibling;
            let elementsContainer = menu.querySelector('.elements-container');
            let elementsContainerWidth = elementsContainer.offsetWidth;

            navAction(elementsContainer, elementsContainerWidth, navWrapper, isDown, startX, scrollLeft);
        });
    }



    function navAction(elementsContainer, elementsContainerWidth, navWrapper, isDown, startX, scrollLeft) {
       if(!!elementsContainer && elementsContainer.offsetWidth > 0 ) {

           let maxWidth = getWidth(elementsContainer);

           let elements = elementsContainer.querySelectorAll('.js-inner-element');
           elements.forEach(function (item) {
               if(item.classList.contains('active')) {
                   elementsContainer.scrollLeft += item.offsetLeft;
               }
           });


           if( (elementsContainerWidth < maxWidth) && !!navWrapper) {

               elementsContainer.classList.add('overflowed');
               navWrapper.classList.remove('hidden');

               let prevNav = navWrapper.querySelector('.previous');
               let nextNav = navWrapper.querySelector('.next');

               prevNav.addEventListener('click', function (event) {
                   event.preventDefault();
                   moveLeft(elementsContainer);
               });

               nextNav.addEventListener('click', function (event) {
                   event.preventDefault();
                   moveRight(elementsContainer);
               });

           } else {
               elementsContainer.classList.remove('overflowed');
               navWrapper.classList.add('hidden');
           }


           if( elementsContainer.classList.contains('overflowed')) {
               elementsContainer.addEventListener('mousedown', (e) => {
                   isDown = true;
                   elementsContainer.classList.add('active');
                   startX = e.pageX - elementsContainer.offsetLeft;
                   scrollLeft = elementsContainer.scrollLeft;
               });
               elementsContainer.addEventListener('mouseleave', () => {
                   isDown = false;
                   elementsContainer.classList.remove('active');
               });
               elementsContainer.addEventListener('mouseup', () => {
                   isDown = false;
                   elementsContainer.classList.remove('active');
               });
               elementsContainer.addEventListener('mousemove', (e) => {
                   if(!isDown) return;
                   e.preventDefault();
                   const x = e.pageX - elementsContainer.offsetLeft;
                   const walk = (x - startX) * 3; //scroll-fast
                   elementsContainer.scrollLeft = scrollLeft - walk;
               });
           }

       }
   }

}

function getWidth(elementsContainer) {
    let elements = elementsContainer.querySelectorAll('.js-inner-element');
    let maxWidth = 0;

    elements.forEach(function (element) {
        var style = element.currentStyle || window.getComputedStyle(element),
            width = element.offsetWidth, // or use style.width
            margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight),
            padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight),
            border = parseFloat(style.borderLeftWidth) + parseFloat(style.borderRightWidth);

        var elementTotalWidth = width + margin - padding + border;
        maxWidth = maxWidth + elementTotalWidth;
    });

    return maxWidth;
}


function moveRight(elem) {
    var w = window.innerWidth
    var right = 0
    function frame() {
        right++  // update parameters
        elem.scrollLeft += right // show frame


        if( w < 992 ) {
            if (right == 20)  // check finish condition
                clearInterval(id)
        } else {
            if (right == 100)  // check finish condition
                clearInterval(id)
        }

    }
    var id = setInterval(frame, 1) // draw every 10ms
}

function moveLeft(elem) {
    var w = window.innerWidth
    var left = 0
    function frame() {
        left++  // update parameters
        elem.scrollLeft -= left // show frame

        if( w < 992 ) {
            if (left == 20)  // check finish condition
                clearInterval(id)
        } else {
            if (left == 100)  // check finish condition
                clearInterval(id)
        }

    }
    var id = setInterval(frame, 1) // draw every 10ms
}

export default setup;
