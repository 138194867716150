function setup() {
    let socialSharing = document.querySelector('.js-social-sharing');


    if( !!socialSharing ){
        let header = document.querySelector('.js-header');
        let siteMap = document.querySelector('.footer-sitemap');
        let mainPage = document.querySelector('.main-page');
        let w = window.innerWidth;
        let h = window.innerHeight;
        let socialSharingInitPositionTop = socialSharing.offsetTop;
        let heightVariable = (85);

        let plusButton = socialSharing.querySelector('.main-container');
        let networkIcons = socialSharing.querySelectorAll('.network-icon');


        if(!!plusButton) {
            plusButton.addEventListener('click', function () {
                if(socialSharing.classList.contains('active')) {
                    socialSharing.classList.remove('active');
                } else {
                    socialSharing.classList.add('active');
                }
            });
        }



        if(!!networkIcons) {
            networkIcons.forEach(function (item) {

                if(
                    item.classList.contains('js-facebook-share') ||
                    item.classList.contains('js-twitter-share') ||
                    item.classList.contains('js-linkedin-share')
                ) {
                    item.addEventListener('click',function () {
                        let hrefItem = item.dataset.url;
                        window.open(hrefItem,'share-dialog','width=626,height=436');

                        if(socialSharing.classList.contains('active')) {
                            socialSharing.classList.remove('active');
                        }
                    });

                } else if( item.classList.contains('print-icon') ) {
                    item.addEventListener('click', function () {

                        //print on landscape mode by default
                        let css = '@page { size: landscape; }',
                            head = document.head || document.getElementsByTagName('head')[0],
                            style = document.createElement('style');

                        style.type = 'text/css';
                        style.media = 'print';

                        if (style.styleSheet){
                            style.styleSheet.cssText = css;
                        } else {
                            style.appendChild(document.createTextNode(css));
                        }

                        head.appendChild(style);

                        window.print();

                        if(socialSharing.classList.contains('active')) {
                            socialSharing.classList.remove('active');
                        }
                    });

                } else if (
                    item.classList.contains('js-email-share') ||
                    item.classList.contains('js-whatsapp-share')
                ) {
                    item.addEventListener('click',function () {
                        if(socialSharing.classList.contains('active')) {
                            socialSharing.classList.remove('active');
                        }
                    });

                } else if ( item.classList.contains('js-copy-url-social') ) {
                    item.addEventListener('click',function () {
                        copyUrlSocialMedia(item);

                        if(socialSharing.classList.contains('active')) {
                            socialSharing.classList.remove('active');
                        }
                    });
                }
            })
        }


        window.addEventListener("scroll", function() {
            let currentPositionTop = window.pageYOffset || document.documentElement.scrollTop;
            let mainPagePositionTop = mainPage.offsetTop;
            let initFixedPosition = mainPagePositionTop + socialSharingInitPositionTop - heightVariable;
            let footerSiteMapPosition = siteMap.offsetTop;

            if( currentPositionTop > initFixedPosition && currentPositionTop <= (footerSiteMapPosition - h + heightVariable) ) {

                socialSharing.classList.add('fixed');
                if( w < 992) {
                    socialSharing.style.top = heightVariable + "px";
                } else {
                    socialSharing.style.top = heightVariable + "px";
                }

            } else if( currentPositionTop > (footerSiteMapPosition - h + heightVariable) ) {

                socialSharing.classList.remove('fixed');
                if( w < 992) {
                    if( mainPage.classList.contains('js-cancer-page-scrolling')) {
                        socialSharing.style.top = (footerSiteMapPosition - h - header.offsetHeight + 171) + "px";
                    } else {
                        socialSharing.style.top = (footerSiteMapPosition - h - header.offsetHeight + 172) + "px";
                    }
                } else {
                    if( mainPage.classList.contains('js-cancer-page-scrolling')) {
                        socialSharing.style.top = (footerSiteMapPosition - h - header.offsetHeight + heightVariable + 2) + "px";
                    } else {
                        socialSharing.style.top = (footerSiteMapPosition - h - header.offsetHeight + heightVariable + 85) + "px";
                    }
                }

            } else if( currentPositionTop <= initFixedPosition) {

                socialSharing.classList.remove('fixed');
                socialSharing.style.top = socialSharingInitPositionTop + "px";

            }
        });
    }
}

function copyUrlSocialMedia(that) {
    let copyUrl = that.dataset.url;
    let succeed;

    document.addEventListener('copy', function(e) {
        e.clipboardData.setData('text/plain', copyUrl);
        e.preventDefault();
    }, true);

    try {
        succeed = document.execCommand('copy');
        alert('Link da página copiado: ' + copyUrl);
    } catch(e) {
        succeed = false;
        alert('Link da página não foi copiado');
    }

}

export default setup;
