import getSiblings from "./getSiblings";
import ajaxCall from "../utils/ajax";

function setup() {
    let starsControl = document.querySelector('.js-stars-control');
    let title = document.querySelector('.js-helpfull-article-title');
    //let submitButton = document.querySelector('.js-user-stars-submit');
    let submitSuccess = document.querySelector('.js-user-stars-submit-success');
    let darkLayer = document.querySelector('.js-dark-layer');
    //let btnCloseSubmitSuccess = document.querySelector('.js-stars-close-submit-success');

    let valueOfStars = null;

    if( !!starsControl ){
        let stars = starsControl.querySelectorAll('.icon-star');
        if(!!stars) {
            stars.forEach(function(star){
                star.addEventListener('click', function(e){

                    /*if(!starsControl.classList.contains('rated')) {
                        this.classList.add('active');
                        valueOfStars = this.dataset.value;
                        getSiblings.getSiblings(this).forEach((sibling) =>{
                            let siblingDataValue = sibling.dataset.value;
                            if(valueOfStars > siblingDataValue) {
                                sibling.classList.add("active");
                            } else {
                                sibling.classList.remove("active");
                            }
                        });

                        let postId = starsControl.dataset.postId;
                        increaseArticleUsefullness(valueOfStars, postId, this, submitSuccess, starsControl, title, darkLayer);
                        starsControl.classList.add('rated');
                    }*/

                    this.classList.add('active');
                    valueOfStars = this.dataset.value;
                    getSiblings.getSiblings(this).forEach((sibling) =>{
                        let siblingDataValue = sibling.dataset.value;
                        if(valueOfStars > siblingDataValue) {
                            sibling.classList.add("active");
                        } else {
                            sibling.classList.remove("active");
                        }
                    });

                    let postId = starsControl.dataset.postId;
                    increaseArticleUsefullness(valueOfStars, postId, this, submitSuccess, starsControl, title, darkLayer);


                })
            });
        }

        /*if(!!submitButton) {
            submitButton.addEventListener('click', function () {
                if(!!valueOfStars) {
                    let postId = this.dataset.postId;
                    increaseArticleUsefullness(valueOfStars, postId, this, submitSuccess, starsControl, title, darkLayer);
                }
            });
        }*/


        /*if(!!btnCloseSubmitSuccess) {
            btnCloseSubmitSuccess.addEventListener('click', function () {
                darkLayer.classList.add('hidden');
                submitSuccess.classList.add('hidden');

                valueOfStars = null;

                if(!!stars) {
                    stars.forEach(function(star){
                        star.classList.remove('active');
                    });
                }
            });
        }*/
    }
}


function increaseArticleUsefullness(review, post_id, btn, submit_success, starsControl, title, darkLayer){

    let url = helpfull_article_handler.url + '?';
    let params = [
        'action=helpfull_article_handler',
        'nonce='+helpfull_article_handler.nonce,
        'post_id='+post_id,
        'review='+review,
    ];

    url += params.join('&');

    ajaxCall('get', url, null)
        .then(response => {
            //console.log(response);
            //submit_success.classList.remove('hidden');
            //darkLayer.classList.remove('hidden');
        })
        .catch(error => {
            console.log(error);
        })
}



export default setup;
