let mainContainer = document.getElementById('video-popup-container');
let closeVideoEl = !!mainContainer ? mainContainer.querySelector('.js-close-video') : null;
let videoContainerArea = document.getElementById('video-popup-container-area');

/**
 * Listeners
 */
function videoClickEvent(videoElements = null) {

        if( !!videoElements ){
            videoElements.forEach(function (videoElement) {
                videoElement.addEventListener('click',function (event) {
                    event.preventDefault();
                    showVideo(this.dataset.popupVideo, videoContainerArea);
                });
            });
        }

        if( !!closeVideoEl ){
            closeVideoEl.addEventListener('click',function () {
                mainContainer.classList.remove('active');
                removeVideo(videoContainerArea);
            });
        }
}

const removeVideo = (videoContainer) => {
    videoContainer.innerHTML = '';
}


const showVideo = (link_video, videoContainer) => {

    let screenWidth = window.innerWidth;
    let screenWidthReal = (0.9*screenWidth).toString();
    let screenHeightReal = ((315/560)*screenWidthReal).toString();

    removeVideo(videoContainer);
    mainContainer.classList.add('active');
    var iframe = document.createElement('iframe');
    iframe.frameBorder=0;



    if( screenWidth < 768 ) {
        iframe.width=screenWidthReal + "px";
        iframe.height=screenHeightReal + "px";
    } else {
        iframe.width="560px";
        iframe.height="315px";
    }

    console.log(link_video);
    iframe.setAttribute("src", link_video);
    videoContainer.appendChild(iframe);
};

export default {videoClickEvent};
