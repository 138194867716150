function setup() {
   let containers = document.querySelectorAll('[data-same-height-container]');

   if( !!containers ){
       containers.forEach(function (container) {
            let elements = container.querySelectorAll('[data-same-height-element]');
            if( !!elements ){
                let maxHeight = 0;
                elements.forEach(function (element) {
                    if( element.offsetHeight > maxHeight ){
                        maxHeight = element.offsetHeight;
                    }
                });

                elements.forEach(function (element) {
                    element.style.height = maxHeight + 'px';
                });
            }
       });
   }
}

export default setup;
