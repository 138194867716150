let availableModules = {};
const setup = modules => {
	if(modules instanceof Object) {
		availableModules = modules;
		// return the function that looks for window.PAGE_MODULES and initializes each one from the list
		return () => {
			if(!!window.PAGE_MODULES && window.PAGE_MODULES instanceof Array) {
				window.PAGE_MODULES.forEach(item => {
					if(availableModules[item] instanceof Function) {
						availableModules[item]();
					}
				})
			}
		}
	}
	return () => {
		console.log ('modules parameter not an object');
	};
};
export const loadModule = module => {
	if(availableModules[module] instanceof Function) {
		availableModules[module]();
	}
}
export const getAvailableModules = () => {
	return availableModules;
}
export default setup;