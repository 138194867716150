/**
 * Listeners
 */
let cookiesPolicyBar, cookiesPolicyBarButton, cookieValue;

function init() {

    cookiesPolicyBar = document.querySelector('.js-cookie-policy');

    if(!!cookiesPolicyBar) {

        cookieValue = getCookie('cookie-policy');

        if( !!cookieValue && cookieValue === 'true' ) {
            // do nothing
        } else {
            cookiesPolicyBar.classList.remove('hidden');
            if( !!cookiesPolicyBar ){
                cookiesPolicyBarButton = cookiesPolicyBar.querySelector('.js-button');
                cookiesPolicyBarButton.addEventListener('click',function () {
                    removeCookiesPolicyBar(cookiesPolicyBar);
                })
            }
        }
    }



}

function removeCookiesPolicyBar(that) {
    if(!that.classList.contains('hidden')) {
        that.classList.add('hidden');
        document.cookie = "cookie-policy=true";
    }
}


function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default init;