const ajaxCall = (method, url, data) => {
  return new Promise (function (resolve, reject) {

    fetch(url, {
      method: method,
      credentials: 'include',
      body: data
    }).then(function(response){

      if ( !response.ok ) {
        reject(response.status, response.statusText);
        return;
      }

      let contentType = response.headers.get('content-type');
      if ( !contentType || contentType.indexOf('application/json') === -1 ) {
        return response;
      } else {
        return response.json();
      }

    }).then(function (data) {
      resolve(data);
    });

  });
};


export default ajaxCall;
