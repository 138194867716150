import Glide from "../../../../node_modules/@glidejs/glide";

function setup() {

    if( !! document.querySelector('[videos-gallery]') ){
        let glide = new Glide('[videos-gallery]', {
            type: 'carousel',
            perView: 3,
            gap: 10,
            peek: 0,
            breakpoints: {
                800: {
                    perView: 1
                }
            }
        });

        glide.mount();
    }




}

export default setup;
