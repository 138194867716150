import Glide from "../../../../node_modules/@glidejs/glide";

function setup() {

    if( !! document.querySelector('.js-pub-slider') ){

        let w = window.innerWidth;
        let glide;

        if( w < 992) {
            glide = new Glide('.pub-slider-container-mobile', {
                type: 'carousel',
                autoplay: 0,
                animationDuration: 400,
                animationTimingFunc: 'linear',
                perView: 1,
                gap: 0,
                peek: 0,
                focusAt: 0
            });
        } else {
            glide = new Glide('.pub-slider-container-desktop', {
                type: 'carousel',
                autoplay: 0,
                animationDuration: 400,
                animationTimingFunc: 'linear',
                perView: 1,
                gap: 0,
                peek: 0,
                focusAt: 0
            });
        }

        glide.mount();
    }




}

export default setup;
