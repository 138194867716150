import ajaxCall from "../utils/ajax";

function setup() {
    let thumbElements = document.querySelectorAll('.js-thumb-helpfull');

    if( !!thumbElements ){
        thumbElements.forEach(function (thumbElement) {
           thumbElement.addEventListener('click', function () {
                increaseArticleUsefullness();
           });
        });
    }
}






function increaseArticleUsefullness(){

    let url = helpfull_article_handler.url + '?';
    let params = [
        'action=helpfull_article_handler',
        'nonce='+helpfull_article_handler.nonce,
        'post_id='+'111',
        'review='+'5',
    ];

    url += params.join('&');

    ajaxCall('get', url, null)
        .then(response => {
            //console.log(response);
            console.log(response.data.test);



            /*if( response.data.html.length > 0 ){
                container.insertAdjacentHTML('beforeend', response.data.html);
                container.dataset.offset = response.data.offset;
                isLoading = false;
            }else{
                loadingElement.remove();
            }

            toggleLoading(false);

            isLoading = false;
            */


        })
        .catch(error => {
            console.log(error);
        })
}

export default setup;
